#card{
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  border-radius: 5px; 

}
#card:hover {
 box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
 background: rgba(0, 0, 0, 0.6);
 background-color: #e6e6e6;
}
#editIcon {
  width: 60px;
  height: 60px;
  position: absolute;
  margin-left: 0;
  transform: translate(10%,-35%);
  z-index: 100;
}
#CardContentedit{
	text-align: 'center';
	background-color: #66cc66;
	color: #fff;
}
#groupIcon {
  width: 60px;
  height: 60px;
  position: absolute;
  margin-left: 0;
  transform: translate(10%,-35%);
  z-index: 100;
}
#CardContentgroup{
	text-align: 'center';
	background-color: #ffb84d;
	color: #fff;
}
#personIcon{
  width: 60px;
  height: 60px;
  position: absolute;
  margin-left: 0;
  transform: translate(10%,-35%);
  z-index: 100;
}
#CardContentperson{
	text-align: 'center';
	background-color: #4dd2ff;
	color: #fff;
}
 #businessIcon{
  width: 60px;
  height: 60px;
  position: absolute;
  margin-left: 0;
  transform: translate(10%,-35%);
  z-index: 100;
}
#CardContentbusiness{
	text-align: 'center';
	background-color:  #ff4d4d;
	color: #fff;
}
